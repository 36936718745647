import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import React, { Children, Component, useEffect, useState } from "react";
import { Tooltip, Text as TextTool } from "react-native-elements";
import IconTimer from "react-native-vector-icons/Entypo";
import AntDesign from "react-native-vector-icons/AntDesign";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  Dimensions,
  TouchableOpacity,
  Pressable,
  ActivityIndicator,
  Modal,
  TextInput,
  useWindowDimensions,
} from "react-native";
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'

import {
  RadioButton,
} from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome5";
import { useSelector } from "react-redux";
import IconCross from "react-native-vector-icons/Entypo";
import {
  MenuProvider,
  MenuContext,
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { postData, baseUrlSocket } from "../../networking/api";
import moment, { duration, isMoment } from "moment";
import SuccessPopup from "../../components/SuccessPopup";
import { getMinutes } from "react-native-paper-dates/lib/typescript/Time/timeUtils";
import { Item } from "react-native-paper/lib/typescript/components/List/List";
export default function ValveView({ navigation, route }) {
  const { OmegaId, OmegaName, basedOn, IMEIId, DeviceId } = route?.params;
  const [timeHr, settimeHr] = useState(0);
  const [timemin, settimemin] = useState(0);
  const [timesec, settimesec] = useState(0);
  var projectName = useSelector((state) => state.projectName);
  const [project_name, setProjectName] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [projectId, setProjectId] = useState("");
  //   const [animations, setAnimations] = useState(constructAnimations());
  const [ValveIsVisible, setValveIsVisible] = useState(false);
  const [isMeasureType, setMeasureType] = useState(2);
  const [isOnline, setIsOnline] = useState("");
  const [valveDetails, setValveDetails] = useState([]);
  const [NewvalveDetails, setNewValveDetails] = useState([]);
  const [waterMeterDetails, setWaterMeterDetails] = useState([]);
  const [analogDetails, setAnalogDetails] = useState([]);
  const [OmegaDetailsGrid, setOmegaDetailsGrid] = useState([]);
  const [valveamount, setvalveamount] = useState<Number>(0);
  const [OmegaIdValue, setOmegaIdValue] = useState(null);
  const [valveIndex, setValveIndex] = useState("");
  const [valveStatus, setvalveStatus] = useState("");
  const [valveName, setValveName] = useState("");
  const [valveState, setValveState] = useState("");
  const [selectedValveIndex, setSelectedValveIndex] = useState("");
  const [refreshflatlist, setrefreshflatlist] = useState(false);
  const [valveDetCount, setvalveDetCount] = useState(0);
  const [vNewIndex, setVNewIndex] = useState("");
  const [count1, setcount1] = useState(0);
  const [socketDetails, setSocketDetails] = useState([]);

  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");

  const [labelValues, setLabelValues] = useState([]);
  const [IMEIDeviceName, setIMEIDeviceName] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  //based-on 
  const [BasedOn, setBasedOn] = useState(basedOn);//<number | null>(null);
  const [IMEIData, setIMEIData] = useState({});
  const [ValveIMEIData, setValveIMEIData] = useState([]);
  const [waterIMEIData, setWaterIMEIData] = useState([]);
  const [pressureIMEIData, setPressureIMEIData] = useState([]);
  const [imeiTabData, setIMEITabData] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  const [initialTime] = useState(new Date());
  const [onlineCheckTime, setOnlineCheckTime] = useState(0);
  const [controlParameter, setControlParameter] = useState([]);
  const [water_by, setWaterBy] = useState(0);
  const [setpul, setSetpul] = useState(0);
  const [decpul, setDecpul] = useState(0);
  const [set_duration, setSetDuration] = useState<any>();
  const [stm, setStm] = useState("");
  const [remainingDuration, setRemainingDuration] = useState(0);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [IMEIData12, setIMEIData12] = useState(false);

  // useEffect(() => {
  //   const fetchDataAndSetTimer = async () => {
  //     try {
  //       // console.log('Starting fetchDataAndSetTimer function');
  //       let projValue = await AsyncStorage.getItem("projectData");
  //       await getIMEITabsData(projValue);
  //       // console.log('getIMEITabsData completed',);
  //       const initialTime = moment(stm).format('HH:mm');
  //       // console.log('initialTime:',initialTime);
  //       const currentTime = moment().format('HH:mm');
  //       // console.log('currentTime:',currentTime);

  //       const durationStr = set_duration;
  //       const duration = moment.duration(durationStr);
  //       const elapsedTime = moment.duration(currentTime.diff(initialTime));
  //       // console.log('elapsedTime:', elapsedTime);
  //       const remainingTime = duration.subtract(elapsedTime).asSeconds();
  //       // console.log('remainingTime:', remainingTime);

  //       if (remainingTime <= 0) {
  //         setRemainingDuration(0);
  //         setIsTimerCompleted(true);
  //       } else {
  //         setRemainingDuration(remainingTime);
  //       }
  //     } catch (error) {
  //       console.error('Error in fetchDataAndSetTimer:', error);
  //     }
  //   };

  //   fetchDataAndSetTimer();
  //   const timeOut = setTimeout(fetchDataAndSetTimer, 5000);
  //   return () => {
  //     // console.log('Clearing timeout');
  //     clearTimeout(timeOut);
  //   };
  // }, [currentTime]);

  useEffect(() => {
    const fetchDataAndSetTimer = async () => {
      try {
        // console.log('Starting fetchDataAndSetTimer function');
        let projValue = await AsyncStorage.getItem("projectData");
        await getIMEITabsData(projValue);
        // console.log('getIMEITabsData completed');

        // Ensure stm is valid
        //const stmstat = "3:00";
        if (stm) {
          const initialTime = stm;

          //// console.log('initialTime:', initialTime.format('HH:mm'));
          // console.log('initialTime:', initialTime);


          const currentTime = moment();
          // console.log('currentTime:', currentTime.format('HH:mm'));

          const durationStr = set_duration;
          const duration = moment.duration(durationStr);
          // console.log('duration:', duration);

          const elapsedTime = moment.duration(currentTime.diff(initialTime));
          // console.log('elapsedTime:', elapsedTime);

          const remainingTime = duration.subtract(elapsedTime).asSeconds();
          // console.log('remainingTime:', remainingTime);

          if (remainingTime <= 0) {
            setRemainingDuration(0);
            setIsTimerCompleted(true);
          } else {
            setRemainingDuration(remainingTime);
            //setIsTimerCompleted(false);
          }
        } else {
          console.error('stm is not set');
        }
      } catch (error) {
        console.error('Error in fetchDataAndSetTimer:', error);
      }
    };

    fetchDataAndSetTimer();

    const timeOut = setTimeout(fetchDataAndSetTimer, 5000);

    return () => {
      // console.log('Clearing timeout');
      clearTimeout(timeOut);
    };
  }, [currentTime]);

  const handleTimerComplete = () => {
    // console.log('Timer completed');
    setIsTimerCompleted(true);
    //setRemainingDuration(0);
  };





  //const isonline = true;

  const timer = `${timeHr < 10 ? `0${timeHr}` : timeHr}:${timemin < 10 ? `0${timemin}` : timemin
    }:${timesec < 10 ? `0${timesec}` : timesec}`;

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // getIMEIDeviceDetailData();
      // getIMEIDetails();
    }, [projectId, projectName])
  );

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)
  }, []);

  // useEffect(() => {
  //   // Check conditions and set BasedOn accordingly
  //   // console.log("basedOn",basedOn);
  //   if (BasedOn == null || BasedOn == 1) {
  //     setBasedOn(1);
  //   } else if (BasedOn == 2) {
  //     // console.log("basedOn",basedOn);
  //     setBasedOn(2);
  //   }
  // }, [BasedOn]);
  // // console.log("BasedOnsetter...",BasedOn);

  useEffect(() => {
    const b = timer.split(":"); // split it at the colons
    const result = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
    setvalveamount(result);
  }, [timer]);

  useEffect(() => {
    if (vNewIndex != "") {
      getValvesData(vNewIndex);
    }
  }, [valveamount]);

  let socket: WebSocket;

  useEffect(() => {
    const connectWebSocket = () => {
      socket = new WebSocket(baseUrlSocket);

      socket.onopen = () => {
        sendMessage();
      };

      socket.onmessage = (event) => {
        const message = event.data;
        if (BasedOn == 1 || BasedOn == null) {
          setSocketDetails(JSON.parse(message));
        }
        if (BasedOn == 2) {
          if (message) {
            setIMEITabData(JSON.parse(message));
          }
        }

        setrefreshflatlist(true);
        // console.log('Received message:', BasedOn, "--", message);
      };

      socket.onerror = (error) => {
        // console.log('WebSocket error:', error);
      };

      socket.onclose = (event) => {
        // console.log('WebSocket connection closed:', event.code, event.reason);
      };
    };

    connectWebSocket();

    setInterval(() => {
      sendMessage()
    }, 5000)

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [OmegaId, projectId]);

  const sendMessage = async () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      let jsonValue = await AsyncStorage.getItem("userData");
      let projValue = await AsyncStorage.getItem("projectData");
      // console.log("BasedOn value:...", BasedOn);
      if (projValue != null) {
        const data = projValue + "-" + OmegaId + "-" + basedOn;
        // console.log("data", data);
        socket.send(data);
      } else if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        const data = userDetailsresponse.project_selected + "-" + OmegaId + "-" + basedOn;
        // console.log("data....", data);
        socket.send(data);
      }
    }
  };

  const downTimeAmount = () => {
    const interval = setInterval(() => {
      setvalveDetCount(valveDetails[selectedValveIndex]["leftover"] - 1)
    }, 1000);
    return () => clearInterval(interval);
  }
  const getAdminData = async () => {
    // console.log("here---------------------------------------------------");
    try {
      let jsonValue = await AsyncStorage.getItem("userData");
      let proCodeId = await AsyncStorage.getItem("projectCode");
      let proName = await AsyncStorage.getItem("projectName");
      setProjectName(JSON.parse(proName));
      let proCode = JSON.parse(proCodeId);
      let projValue = await AsyncStorage.getItem("projectData");
      if (BasedOn !== 2) {
        //getValveOmegaDetails(projValue);
      }
      //getValveOmegaDetails(projValue);
      let projectNewId;
      if (projValue != null) {
        projectNewId = projValue
        setProjectId(projValue);
      } else if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        projectNewId = userDetailsresponse.project_selected
        setProjectId(userDetailsresponse.project_selected);
      }
      if (BasedOn == 2) {
        getIMEITabsData(projectNewId);
      }
      if (BasedOn !== 2) {
        getValveOmegaDetails(projectNewId);
      }
      getIMEIDeviceDetailData(projectNewId);
      getIMEIDetails(projectNewId);
    } catch (err) {
      // console.log("err", err);

    }
  };
  const getValvesData = async (vIndex) => {
    var vIn = vIndex;

    setVNewIndex(vIn);
  };
  const getCounts = (count1) => {
    if (count1 == 1) {
      // downTimeAmount();
      startProgram();
    }
  }

  async function calculateTotalMinutes(hours = 0, minutes = 0) {
    return hours * 60 + minutes;
  }

  //start Program
  const startProgram = async () => {
    if (vNewIndex != "") {
      var dIndex = valveDetails.filter((item) => (item.index == vNewIndex))
      dIndex[0]["leftover"] = Number(valveamount);
      dIndex[0]["state"] = valveState;
      dIndex[0]["status"] = valveStatus;
      dIndex[0]["index"] = vNewIndex;
      dIndex[0]["index"] = vNewIndex;
      dIndex[0]["name"] = valveName;
      dIndex[0]["initialTime"] = valveamount;

      let newData = JSON.stringify(dIndex).slice(1, -1)
      let valveData = JSON.parse(newData);
      valveDetails[selectedValveIndex] = valveData;

      setValveDetails(valveDetails);
      setrefreshflatlist(true);
    }
    setIsLoading(true);
    try {
      var startProgram = JSON.stringify({
        omega_id: OmegaId,
        index: valveIndex,
        type: isMeasureType,
        amount: valveamount,
      });
      const startProgramResponse = await postData(
        startProgram,
        "reports/valveStart"
      );
      setIsLoading(false);
      var code = startProgramResponse.code;
      if (code == 1) {
      } else {
        setIsLoading(false);
        dIndex[0]["initialTime"] = 0
        //alert(startProgramResponse.message);
      }
    } catch (error) {
      dIndex[0]["initialTime"] = 0
      setIsLoading(false);
      // // console.log(error);
    }
  };
  //stop Program
  const stopProgram = async (index) => {
    setIsLoading(true);
    try {
      var stopProgram = JSON.stringify({
        omega_id: OmegaId,
        index: index,
      });
      // // console.log("stop program payload", stopProgram);
      const stopProgramResponse = await postData(
        stopProgram,
        "reports/valveStop"
      );
      setIsLoading(false);
      var code = stopProgramResponse.code;
      if (code == 1) {
        // // console.log(" stop valve", stopProgramResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // // console.log(error);
    }
  };

  const AutoOptionSelected = async (type) => {
    setIsLoading(true);
    try {
      var AutoOptionSelected = JSON.stringify({
        "omega_id": OmegaId,
        "operation": type
      });
      // console.log("menu option selected payload", AutoOptionSelected);
      const autoOptionSelectedResponse = await postData(
        AutoOptionSelected,
        "/masters/changeautomanualforimei"
      );
      // setIsLoading(false);
      var code = autoOptionSelectedResponse.code;
      if (code == 1) {
        // // console.log(" stop valve", stopProgramResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      // // console.log(error);
    }
  };
  //Get details when click on omega id
  const getValveOmegaDetails = async (proId) => {
    try {
      var OmegaDetails = JSON.stringify({
        omega_id: OmegaId,
        project_id: proId,
      });
      const OmegaDetailsResponse = await postData(
        OmegaDetails,
        "dashboards/dashboardGridView"
      );
      setIsLoadingSync(false);
      var code = OmegaDetailsResponse.code;
      if (code == 1) {
        setIsOnline(OmegaDetailsResponse.data[0].online);
        setValveDetails(OmegaDetailsResponse.data[0].valves);
        setWaterMeterDetails(OmegaDetailsResponse.data[0].watermeter);
        setAnalogDetails(OmegaDetailsResponse.data[0].analogs);
        setOmegaDetailsGrid(OmegaDetailsResponse.data);
      } else {
        setIsLoadingSync(false);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // // console.log(error);
    }
  };

  const getDetailsFromSocket = (index) => {
    var mainindex = index;
    var leftover = 0;
    var state = 0;
    var valveIndex = 0;
    var mainsIndex = "";
    var arr: number[] = []
    // console.log("valveDetails", valveDetails);
    // console.log("socketDetails", socketDetails);
    // console.log("arr", arr);



    try {
      if (socketDetails[0].programs && socketDetails[0].programs.length > 0) {
        for (let j = 0; j < socketDetails[0].programs.length; j++) {
          if (socketDetails[0].programs[j].valve == valveDetails[mainindex].index) {
            // console.log("socketDetails[0].programs[j]", j)
            mainsIndex = socketDetails[0].programs[j].index;
            valveIndex = socketDetails[0].programs[j].valve;
            leftover = Number(socketDetails[0].programs[j].leftover);
            state = socketDetails[0].programs[j].state;
            arr.push(Number(socketDetails[0].programs[j].leftover))
            break;
          }
        }
      }
    } catch (error) {
      // console.log("socket disconnected121212", error);
    }

    const data = { "leftover": leftover, "state": state }

    return data;
  }
  const getIMEIDeviceDetailData = async (proId) => {
    //setIsLoading(true);
    // console.log("IMEIId", IMEIId);
    try {
      var DeviceDetailsIMEIData = JSON.stringify({
        module: "device_profile",
        condition: {
          //"imei_no": "869523059681875",
          project_id: proId,
          "imei_no": OmegaId,
        }
      });
      const DeviceDetailsIMEIResponse = await postData(
        DeviceDetailsIMEIData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      // console.log("DeviceDetailsIMEIResponse ==> ", DeviceDetailsIMEIResponse);
      var code = DeviceDetailsIMEIResponse.code;
      if (code == 1) {
        const IMEIMonitoringData = DeviceDetailsIMEIResponse.data[0];
        setIMEIDeviceName(IMEIMonitoringData);

        const online_check_time = DeviceDetailsIMEIResponse.data[0].online_check_time;
        let totalMinutes = 0;
        if (online_check_time) {
          totalMinutes = await calculateTotalMinutes(online_check_time.hours, online_check_time.minutes);
        }
        setOnlineCheckTime(totalMinutes);

        //setBasedOn(IMEIMonitoringData.based_on);
        // console.log("ValveViewData", IMEIMonitoringData.imei_static_label_data);
        setLabelValues(IMEIMonitoringData.imei_static_label_data);
      } else {
        // Error case
        console.error("Failed to fetch DatatableLabelResponse:", DeviceDetailsIMEIResponse.message)
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error ==> ", error);
    }
  };


  const getIMEIDetails = async (proId) => {
    //setIsLoading(true);
    try {
      var IMEIDetailsData = JSON.stringify({
        isBasedOn: true,
        module: "live_monitoring",
        condition: {
          //"device_id": "1233",
          project_id: proId,
          "omega_id": OmegaId,
        }
      });
      const IMEIDetailsDataResponse = await postData(
        IMEIDetailsData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      console.log("IMEIDetailsDataResponse ==> ", IMEIDetailsData);
      console.log("IMEIDetailsDataResponse ==> ", IMEIDetailsDataResponse);
      var code = IMEIDetailsDataResponse.code;
      if (code == 1) {
        // console.log("ValveData...", IMEIDetailsDataResponse.data[0].valves);
        // console.log("PressData...", IMEIDetailsDataResponse.data[0].analogs);
        // console.log("WaterData...", IMEIDetailsDataResponse.data[0].watermeter);

        const modifiedControlParameter = {
          ...IMEIDetailsDataResponse.data[0].control_parameter,
          water_by: 1
          // water_by: 2

        };

        setIMEIData(IMEIDetailsDataResponse.data[0]);
        console.log("IMEITable:", IMEIDetailsDataResponse.data[0]);
        setIsOnline(IMEIDetailsDataResponse.data[0].isonline);
        //setBasedOn(IMEIDetailsDataResponse.data[0].based_on);
        setValveIMEIData(IMEIDetailsDataResponse.data[0].valves);
        setWaterIMEIData(IMEIDetailsDataResponse.data[0].watermeter);
        console.log("watermeter-->", waterIMEIData);
        setPressureIMEIData(IMEIDetailsDataResponse.data[0].analogs);
        setControlParameter(IMEIDetailsDataResponse.data[0].control_parameter);
        setWaterBy(IMEIDetailsDataResponse.data[0].control_parameter.water_by);
        // console.log("Water by:", IMEIDetailsDataResponse.data[0].control_parameter.water_by);
        // console.log("SET DURATION:", IMEIDetailsDataResponse.data[0].control_parameter.set_duration);
        setSetDuration(IMEIDetailsDataResponse.data[0].control_parameter.set_duration)
        //setSetDuration("00:05");
        // // console.log("Control parameter",IMEIDetailsDataResponse.data[0].control_parameter);
        const dynamic_based_on = IMEIDetailsDataResponse.data[0].based_on ? IMEIDetailsDataResponse.data[0].based_on : 2
        setBasedOn(dynamic_based_on)
      } else {
        // Error case
        console.error("Failed to fetch DatatableLabelResponse:", IMEIDetailsDataResponse.message)
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error ==> ", error);
    }
  };
  const getIMEITabsData = async (proId) => {
    try {
      let projValue = await AsyncStorage.getItem("projectData");
      console.log("OmegaDetailsGrid.based_on.....",OmegaDetailsGrid[0].based_on);
      if(OmegaDetailsGrid && OmegaDetailsGrid[0].based_on == 1){
        return false;
      }
      var IMEITabData = JSON.stringify({
        active: 1,
        condition: {
          project_id: projectId,
          based_on: BasedOn,
          "omega_id": OmegaId,
        },
        module: "devices_master"
      });
      const IMEITabDataResponse = await postData(
        IMEITabData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = IMEITabDataResponse.code;
      if (code == 1) {
        const imeiData = JSON.parse(IMEITabDataResponse.data[0].imei_data);
        const { setpul, decpul, stm } = imeiData;
        setSetpul(setpul);
        setDecpul(decpul);
        setStm(stm);
        setIMEIData12(imeiData)
      } else {
        // Error case
        console.error("Failed to fetch DatatableLabelResponse:", IMEITabDataResponse.message)
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error ==> ", error);
    }
  }
  const screenwidth = Dimensions.get('window').width
  const isMobile = screenwidth < 768;


  const renderValveItem = (item, index) => {
    const data = getDetailsFromSocket(index);
    var tempProgramArray = [];
    var tempValveArray = [];
    var tempLeft: number | any[] = []
    var leftover = data.leftover
    var state = data.state
    var flow = 0
    if (socketDetails.length != 0) {
      var tempsocketDetailsValve = socketDetails[0].valves
      if (tempsocketDetailsValve) {
        for (let i = 0; i < tempsocketDetailsValve.length; i++) {
          const element = tempsocketDetailsValve[i];
          if (element.inputNumber === item.inputNumber) {
            tempValveArray.push(element);
          }
        }
      }

      var tempsocketDetails = socketDetails[0].programs
      // console.log("tempsocketDetails", tempsocketDetails);

      if (tempsocketDetails) {
        for (let i = 0; i < tempsocketDetails.length; i++) {
          const element = tempsocketDetails[i];
          if (element.valve === Number(item.inputNumber)) {
            flow = element.flow
            tempProgramArray.push(element);
            tempLeft.push(element.leftover)
          }
        }
      }
    }
    // console.log("tempValveArray", tempValveArray);
    // console.log("tempProgramArray", tempProgramArray);
    // console.log("tempLeft", tempLeft);


    var initialTimeNew = 0
    if (tempProgramArray.length > 0) {
      initialTimeNew = (100 / tempProgramArray[0].perc) * tempProgramArray[0].leftover;
    }
    var alert = ''
    if (tempValveArray.length > 0) {
      if (tempValveArray[0].status == 'OPEN_WITH_ERROR_WITH_LOW_FLOW' || tempValveArray[0].status == 'CLOSE_WITH_ERROR_NO_PULSE' || tempValveArray[0].status == 'OPEN_WITH_ERROR_NO_PULSE') {
        alert = 'No pulse'
        // console.log("inside if");
      } else if (tempValveArray[0].status == 'CLOSE_WITH_ERROR_HIGH_FLOW' || tempValveArray[0].status == 'OPEN_WITH_ERROR_HIGH_FLOW') {
        alert = 'High Flow'
        // console.log("inside else if");
      } else if (tempValveArray[0].status == 'CLOSE_WITH_ERROR_LOW_FLOW' || tempValveArray[0].status == 'OPEN_WITH_ERROR_LOW_FLOW') {
        alert = 'High Flow'
        // console.log("inside else if 2");

      } else {
        alert = ''
        // console.log("inside else ");

      }
    }
    // console.log("alert", alert);
    // console.log("leftover", leftover);
    // console.log("initialTimeNew", initialTimeNew);

    function secondsToHMS(totalSeconds: any) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      // Format the result
      const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // // console.log("formattedTime", formattedTime);

      return formattedTime;
    }
    return (
      <View style={{}}>
        <View style={{ padding: 10 }}>
          <View style={{ alignSelf: 'center', marginBottom: 5 }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{
                alignSelf: "center",
                marginLeft: 20,
                fontWeight: "500",
                fontSize: 18
              }}>
                {item.name}

                <View
                  style={{
                    width: "10%",
                    left: 5,
                    top: "0px",
                  }}
                >
                  <Menu>
                    <MenuOptions
                      optionsContainerStyle={{
                        borderWidth: 1,
                        zIndex: 5,
                        flex: 1,
                        width: "10%",
                        // width: useWindowDimensions().width,
                        position: "absolute",
                        marginTop: -92,
                        marginLeft: -150,
                        borderColor: "#7b757573",
                        height: 30
                      }}
                    >
                      {tempProgramArray.length == 0 && (
                        <MenuOption
                          onSelect={() => {
                            setSelectedValveIndex(index)
                            setValveIndex(item.index);
                            setValveName(item.name)
                            setvalveStatus(item.status);
                            setValveState(item.state)
                            getValvesData(item.index, item.name);
                            setValveIsVisible(true);
                            setvalveamount(0);
                          }}
                          text={"Start"}
                        />
                      )}
                      {tempProgramArray.length > 0 && (
                        <MenuOption
                          onSelect={() => {
                            stopProgram(item.index);
                          }}
                          text={"Stop"}
                        />
                      )}
                    </MenuOptions>
                    <MenuTrigger
                      text=""
                      customStyles={{
                        triggerWrapper: {
                          width: 30,

                        },
                      }}
                    >
                      <IconCross
                        color="#000"
                        name="dots-three-vertical"
                        size={15}
                      />

                    </MenuTrigger>
                  </Menu>
                </View>
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginLeft: '20%', }}>
              <Text style={{
                fontWeight: "500",
                fontSize: 30
              }}>
                {flow}
              </Text>
              <View style={{ marginLeft: 10, alignSelf: 'center' }}>
                <Text style={{
                  fontWeight: "500",
                  fontSize: 10,
                  color: 'grey'
                }}>
                  Flow
                </Text>
                <Text style={{
                  fontWeight: "500",
                  fontSize: 10,
                  color: 'grey'
                }}>
                  (m3/h)
                </Text>
              </View>
            </View>
          </View>
          <CountdownCircleTimer
            isPlaying={state === 3 ? false : true}
            // duration={item.leftover}
            // duration={item.initialTime !== null ? item.initialTime : 0}
            duration={initialTimeNew}
            // initialRemainingTime={leftover !== 0 ? item.leftover : leftover}
            // initialRemainingTime={leftover}
            // key={leftover}
            initialRemainingTime={tempProgramArray.length > 0 ? tempProgramArray[0].leftover : 0}
            key={tempProgramArray.length > 0 ? tempProgramArray[0].leftover : ''}
            colors={['#009df0', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
            rotation={'counterclockwise'}
            size={140}
          >
            {({ remainingTime }) => {
              <Text></Text>
              var hours = Math.floor(remainingTime / 3600).toString()
              var minutes = Math.floor((remainingTime % 3600) / 60).toString()
              var seconds = Math.floor((remainingTime % 60)).toString()
              hours = ('0' + hours).slice(-2)
              minutes = ('0' + minutes).slice(-2)
              seconds = ('0' + seconds).slice(-2)
              return (
                <View>
                  {/* <Text style={{
                    fontWeight: "500",
                    fontSize: 14,
                    width: 90
                  }}

                    numberOfLines={1}>
                    Descriptionssss
                    {/* item.description */}
                  <Text style={{
                    alignSelf: "center",
                    marginTop: 2,
                    fontWeight: "500",
                    fontSize: 15
                  }}>
                    {item.measurementype == 1 ?
                      // secondsToHMS(leftover)
                      (leftover / 1000) + ' m³'
                      :
                      new Date(leftover * 1000).toISOString().slice(11, 19)
                    }
                  </Text>
                  <View style={{ height: 25 }}>
                    {alert != '' &&
                      <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                        <AntDesign
                          color="red"
                          name="exclamationcircleo"
                          size={16}
                        />
                        <Text style={{
                          fontWeight: "500",
                          fontSize: 12,
                          color: 'red',
                          marginLeft: 5,
                        }}>
                          {alert}
                        </Text>
                      </View>
                    }
                  </View>
                </View>
              )
            }
            }


          </CountdownCircleTimer>

        </View>
      </View>
    );
  };

  const renderValveIMEIItem = (item, index) => {
    var initialTimeNew = 0;
    var alert = '';
    // console.log("renderValveIMEIItem", item);


    let imeiTabDataObject;
    let flow1;
    try {
      imeiTabDataObject = JSON.parse(imeiTabData);
      flow1 = IMEIData12.flow;
    } catch (err) {
      // console.log("err", err);
    }
    const alertTimeMinutes = parseInt(item.alert_time);
    const targetTime = new Date(initialTime.getTime() + alertTimeMinutes * 60000);
    if (currentTime >= targetTime) {
      if (flow1 < item.percentage_for_low_flow) {
        alert = 'Low Pressure';
      } else if (flow1 > item.percentage_for_high_flow) {
        alert = 'High Pressure';
      }
    }
    const isPlaying = Boolean(isOnline && !isTimerCompleted);
    // console.log("isPlaying:", isPlaying);
    const timerColors = isOnline
      ? ['#009df0', '#F7B801', '#A30000', '#A30000']
      : ['#D3D3D3', '#D3D3D3', '#D3D3D3', '#D3D3D3'];

    const colorsObject = {
      0: timerColors[0],
      1: timerColors[1],
      2: timerColors[2],
      3: timerColors[3],
    };

    const durationStr = set_duration;
    const startTimeStr = stm;
    const duration = moment.duration(durationStr);
    const startTime = moment(startTimeStr, "HH:mm");
    const startTimeNumber = startTime.hour() * 100 + startTime.minute();
    console.log("startTimeNumber", stm);

    return (
      <View style={{ padding: 10 }}>
        <View style={{ alignSelf: 'center', marginBottom: 5 }}>
          <Text style={{
            alignSelf: "center",
            marginLeft: 20,
            fontWeight: "500",
            fontSize: 18
          }}>
            {item.name}
            <View
              style={{
                width: "10%",
                left: 5,
                top: "0px",
              }}
            >
              <Menu>
                <MenuOptions
                  optionsContainerStyle={{
                    borderWidth: 1,
                    zIndex: 5,
                    flex: 1,
                    width: "10%",
                    // width: useWindowDimensions().width,
                    position: "absolute",
                    marginTop: -92,
                    marginLeft: -150,
                    borderColor: "#7b757573",
                    height: 30
                  }}
                >
                  <MenuOption
                    onSelect={() => {
                      // console.log("Auto selected");
                      // Add your logic for Auto option here
                    }}
                    text={"Auto"}
                    customStyles={{
                      optionWrapper: {
                        backgroundColor: '#fff',
                        padding: 10,
                        marginVertical: 5,
                        borderRadius: 5,
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.3,
                        shadowRadius: 2,
                        elevation: 2,
                      },
                    }}
                  />
                  <MenuOption
                    onSelect={() => {
                      // console.log("Manual selected");
                      // Add your logic for Manual option here
                    }}
                    text={"Manual"}
                    customStyles={{
                      optionWrapper: {
                        backgroundColor: '#fff',
                        padding: 10,
                        marginVertical: 5,
                        borderRadius: 5,
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.3,
                        shadowRadius: 2,
                        elevation: 2,
                      },
                    }}
                  />
                </MenuOptions>
                <MenuTrigger
                  text=""
                  customStyles={{
                    triggerWrapper: {
                      width: 30,

                    },
                  }}
                >
                  <IconCross
                    color="#000"
                    name="dots-three-vertical"
                    size={15}
                  />

                </MenuTrigger>
              </Menu>
            </View>
          </Text>
          <View style={{ flexDirection: 'row', marginLeft: '20%', marginTop: 10 }}>
            <Text style={{
              fontWeight: "500",
              fontSize: 30
            }}>
              {IMEIData12.flow}
            </Text>
            <View style={{ marginLeft: 10, alignSelf: 'center' }}>
              <Text style={{
                fontWeight: "500",
                fontSize: 10,
                color: 'grey'
              }}>
                Flow
              </Text>
              <Text style={{
                fontWeight: "500",
                fontSize: 10,
                color: 'grey'
              }}>
                (m3/h)
              </Text>
            </View>
          </View>
        </View>
        <CountdownCircleTimer
          key={remainingDuration}
          isPlaying={isPlaying}
          duration={startTimeNumber}
          initialRemainingTime={IMEIData12.sol == 0 ? 0 : remainingDuration}
          colors={colorsObject}
          colorsTime={[420, 300, 120, 0]}
          rotation={'counterclockwise'}
          size={140}
          onComplete={handleTimerComplete}
        >
          {({ remainingTime }) => {
            let showDuration = '00:00:00';
            let alertMessage = '';

            if (IMEIData12.sol === 0) {
              showDuration = '00:00:00';
            } else {
              const hours = Math.floor(remainingTime / 3600).toString().padStart(2, '0');
              const minutes = Math.floor((remainingTime % 3600) / 60).toString().padStart(2, '0');
              const seconds = Math.floor((remainingTime % 60)).toString().padStart(2, '0');

              showDuration = `${hours}:${minutes}:${seconds}`;

              if (remainingTime <= 0) {
                showDuration = '00:00:00';
              }

              if (isOnline) {
                if (water_by == 0) {
                  const waterUsageLiters = setpul - decpul;
                  const waterUsageCubicMeters = waterUsageLiters / 1000;
                  showDuration = `${waterUsageCubicMeters.toFixed(2)} m³`;
                } else if (water_by == 1) {
                  const durationStr = set_duration;
                  const startTimeStr = stm;
                  const duration = moment.duration(durationStr);
                  const startTime = moment(startTimeStr, "HH:mm");
                  const currentTime = moment();
                  const elapsedTime = moment.duration(currentTime.diff(startTime));
                  const remainingDuration = duration.subtract(elapsedTime);

                  showDuration = moment.utc(remainingDuration.asMilliseconds()).format("HH:mm:ss");

                  if (remainingDuration.asSeconds() <= 0) {
                    showDuration = '00:00:00';
                  }
                }
              } else {
                showDuration = water_by == 0 ? '0 m³' : '00:00:00';
              }
            }

            const lowFlowThreshold = IMEIData.valves[0].percentage_for_low_flow ? Number(IMEIData.valves[0].percentage_for_low_flow) : 0;
            const highFlowThreshold = IMEIData.valves[0].percentage_for_high_flow ? Number(IMEIData.valves[0].percentage_for_high_flow) : 0;

            if (IMEIData12.sol === 1 && (IMEIData12.flow < lowFlowThreshold || IMEIData12.flow > highFlowThreshold)) {
              alertMessage = 'Open with Alert';
            }

            console.log("Device Data : ", IMEIData);
            console.log("imei_data: ", IMEIData12);

            return (
              <View>
                <Text style={{
                  alignSelf: "center",
                  marginTop: 2,
                  fontWeight: "500",
                  fontSize: 15
                }}>
                  {showDuration}
                </Text>
                <View style={{ height: 25 }}>
                  {alertMessage && (
                    <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                      <AntDesign
                        color="red"
                        name="exclamationcircleo"
                        size={16}
                      />
                      <Text style={{
                        fontWeight: "500",
                        fontSize: 12,
                        color: 'red',
                        marginLeft: 5,
                      }}>
                        {alertMessage}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            );
          }}
        </CountdownCircleTimer>


        {/* <CountdownCircleTimer
          key={remainingDuration}
          //isPlaying={!isTimerCompleted}
          isPlaying={isPlaying}
          duration={startTimeNumber}
          initialRemainingTime={remainingDuration}
          //colors={['#009df0', '#F7B801', '#A30000', '#A30000']}
          colors={colorsObject}
          //colorsTime={[7, 5, 2, 0]}
          colorsTime={[420, 300, 120, 0]}
          rotation={'counterclockwise'}
          size={140}
          onComplete={handleTimerComplete}
        >
          {({ remainingTime }) => {
            const hours = Math.floor(remainingTime / 3600).toString().padStart(2, '0');
            const minutes = Math.floor((remainingTime % 3600) / 60).toString().padStart(2, '0');
            const seconds = Math.floor((remainingTime % 60)).toString().padStart(2, '0');

            let showDuration = `${hours}:${minutes}:${seconds}`
            if (remainingTime <= 0) {
              showDuration = '00:00:00';
            }

            if (isOnline) {
              if (water_by == 0) {
                const waterUsageLiters = setpul - decpul;
                const waterUsageCubicMeters = waterUsageLiters / 1000;
                showDuration = `${waterUsageCubicMeters.toFixed(2)} m³`;
              } else if (water_by == 1) {
                const durationStr = set_duration;
                const startTimeStr = stm;
                const duration = moment.duration(durationStr);
                const startTime = moment(startTimeStr, "HH:mm");
                const currentTime = moment();
                const elapsedTime = moment.duration(currentTime.diff(startTime));
                const remainingDuration = duration.subtract(elapsedTime);

                showDuration = moment.utc(remainingDuration.asMilliseconds()).format("HH:mm:ss");
                console.log("set_duration", set_duration, "stm", stm, "currentTime", currentTime, "elapsedTime", elapsedTime);
                console.log("showDuration", showDuration);

                if (remainingDuration.asSeconds() <= 0) {
                  showDuration = '00:00:00';
                }
              }
            } else {
              showDuration = water_by == 0 ? '0 m³' : '00:00:00';
            }
            return (
              <View>
                <Text style={{
                  alignSelf: "center",
                  marginTop: 2,
                  fontWeight: "500",
                  fontSize: 15
                }}>
                  {showDuration}
                </Text>
                <View style={{ height: 25 }}>
                  {alert !== '' && (
                    <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                      <AntDesign
                        color="red"
                        name="exclamationcircleo"
                        size={16}
                      />
                      <Text style={{
                        fontWeight: "500",
                        fontSize: 12,
                        color: 'red',
                        marginLeft: 5,
                      }}>
                        {alert}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            );
          }}
        </CountdownCircleTimer> */}
      </View>
    );
  };
  //omega based pressure transmitter block
  const renderItemLeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var value = 0;
    var error = "";

    try {
      if (socketDetails[0].analogs && socketDetails[0].analogs.length > 0) {
        for (let j = 0; j < socketDetails[0].analogs.length; j++) {
          if (Number(socketDetails[0].analogs[j].index) === Number(analogDetails[mainindex].index)) {
            name = socketDetails[0].analogs[j].name;
            value = socketDetails[0].analogs[j].value;
            error = socketDetails[0].analogs[j].error;
            break;
          }
        }
      }
    } catch (error) {
      // console.log("socket disconnected121212", error);
    }
    return (
      <View style={{ justifyContent: "space-between" }}>
        {isOnline == "1" ?
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{name !== "" ? name : ""}</Text>
              </Tooltip>
            </View>
            {error ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 0,
                  color: "red",
                  marginTop: 5,
                  fontWeight: "700",
                  marginRight: 20,
                  padding: 2,
                }}
              >
                {error}
              </Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  // padding: 2,
                  marginTop: 10,
                  marginRight: 20,
                }}
              > </Text>
            )}

            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 15,
                marginTop: 5,
                color:
                  item.level == "Open"
                    ? "#0fbf00"
                    : item.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: 30 }}>{value}</Text> {item.unitOfMeasure}
            </Text>
          </View>
          :
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {item.name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{BasedOn == 2 ? item.name.toLowerCase() : item.name !== "" ? item.name.toLowerCase() : ""}</Text>
              </Tooltip>
            </View>
            {item.error ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 0,
                  color: "red",
                  marginTop: 5,
                  fontWeight: "700",
                  marginRight: 20,
                }}
              >
                {item.error}
              </Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  // padding: 2,
                  marginTop: 14,
                  marginRight: 20,
                }}
              > </Text>
            )}

            <Text
              style={{
                alignSelf: "center",
                fontWeight: "700",
                fontSize: 15,
                color:
                  item.level == "Open"
                    ? "#0fbf00"
                    : item.level == "Close"
                      ? "#bababa"
                      : "#000000",
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: 30 }}>--</Text> {item.unitOfMeasure}
            </Text>
          </View>
        }
      </View>
    );
  };

  // IMEI based pressureTransmitter block
  const renderPressureIMEILeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var value = 0;
    var error = "";
    if (imeiTabData) {
      try {
        var imeiTabDataObject = JSON.parse(imeiTabData);
        var flow1 = imeiTabDataObject.pres; // for pressure
        // console.log("pres...", flow1);
        var flow2 = imeiTabDataObject.flow; // for level
        // console.log("flow2", flow2);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }

    // pressure
    const alertTimeMinutes = parseInt(item.alert_time);
    const targetTime = new Date(initialTime.getTime() + alertTimeMinutes * 60000);

    if (currentTime >= targetTime) {
      if (flow1 < item.low_pressure) {
        error = 'Low Pressure';
      } else if (flow1 > item.high_pressure) {
        error = 'High Pressure';
      }
    }

    //level
    if (flow2 < item.min_level) {
      error = 'Min Level';
    } else if (flow2 > item.max_level
    ) {
      error = 'Max Level';
    }
    let imeiDataData;
    let pres = 0.00;
    let levl = 0.0;
    try {
      imeiDataData = JSON.parse(imeiTabData)
      pres = imeiDataData.pres.toFixed(2);
      levl = imeiDataData.levl.toFixed(2);
    } catch (err) {
      // console.log("err", err);
    }


    // presure imei
    if (Number(IMEIData12.pres) < Number(item.low_pressure)) {
      error = 'Low Pressure';
    }
    if (Number(IMEIData12.pres) > Number(item.high_pressure)) {
      error = 'High Pressure'
    }

    // leve imei
    if (Number(IMEIData12.levl) < Number(item.min_level)) {
      error = 'Low Level';
    }
    if (Number(IMEIData12.levl) > Number(item.max_level)) {
      error = 'High Level'
    }

    return (
      <View style={{ justifyContent: "space-between", marginBottom: isMobile ? 10 : 0 }}>
        <View
          style={{
            backgroundColor: "#009df01f",
            marginLeft: 10,
            padding: 7,
            height: 100,
            width: 200
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "95%",
              alignSelf: "flex-start",
              marginLeft: 5,
              marginTop: 5,
              justifyContent: "space-between",
            }}
          >
            <Tooltip popover={<Text style={{ color: 'white' }}> {item.name}</Text>}>
              <Text style={{ fontWeight: "700" }}>{BasedOn == 2 ? item.name.toLowerCase() : item.name !== "" ? item.name.toLowerCase() : ""}</Text>
            </Tooltip>
          </View>
          {error ? (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 0,
                color: "red",
                marginTop: 5,
                fontWeight: "700",
                marginRight: 20,
              }}
            >
              {error}
            </Text>
          ) : (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 5,
                color: "red",
                // padding: 2,
                marginTop: 14,
                marginRight: 20,
              }}
            ></Text>
          )}

          <Text
            style={{
              alignSelf: "center",
              fontWeight: "700",
              fontSize: 15,
              color:
                item.level == "Open"
                  ? "#0fbf00"
                  : item.level == "Close"
                    ? "#bababa"
                    : "#000000",
              textAlign: "center",
            }}
          >
            {basedOn == 2 ?
              (
                item.index == 1 ?
                  <>
                    <Text style={{ fontSize: 30 }}>{parseFloat(IMEIData12.pres).toFixed(2)}</Text> {item.unit}
                  </>
                  :
                  <View style={{ flexDirection: "row", alignItems: 'baseline' }}>
                    <Text style={{ fontSize: 30 }}> {parseFloat(IMEIData12.levl).toFixed(2)} </Text>  {item.unit}
                  </View>
              )
              :
              <>
                <Text style={{ fontSize: 30 }}>-- </Text>{item.unit}
              </>
            }


            {/* <Text style={{ fontSize: 30 }}>--</Text> {item.unit} */}
          </Text>
        </View>
      </View>
    );
  };
  // Omega based watermeter blocks
  const renderWaterMtLeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var totalizer = 0;
    var status = 0;
    var error = "";
    var flow = 0;
    var inputNumber = 0;



    try {
      if (socketDetails[0].watermeter && socketDetails[0].watermeter.length > 0) {
        for (let j = 0; j < socketDetails[0].watermeter.length; j++) {
          if (Number(socketDetails[0].watermeter[j].index) === Number(waterMeterDetails[mainindex].index)) {
            name = socketDetails[0].watermeter[j].name;
            totalizer = socketDetails[0].watermeter[j].totalizer;
            status = socketDetails[0].watermeter[j].status;
            error = socketDetails[0].watermeter[j].error;
            flow = socketDetails[0].watermeter[j].flow;
            inputNumber = socketDetails[0].watermeter[j].inputNumber;
            break;
          }
        }
      }
    } catch (error) {
      // console.log("error", error);
    }

    return (
      // <View style={{ justifyContent: "space-between", width: "100%" }}>
      <View style={{ justifyContent: "space-between" }}>
        {name != undefined && name != "" && isOnline == "1" && inputNumber < 3 && (
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{name}</Text>
              </Tooltip>
            </View>
            <View>
              {inputNumber < 3 ?
                <Text style={{ alignSelf: "flex-start" }}> {totalizer / 1000} m³</Text>
                :
                <Text style={{ alignSelf: "flex-start", marginTop: 15 }}>{""}</Text>
              }
            </View>
            {error != "" ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            )}
            {true &&
              <Text
                style={{
                  width: "100%",
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 15,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: 30 }}>{IMEIData12.flow ? IMEIData12.flow: 0}</Text> m³/h
              </Text>
            }
            {inputNumber > 2 && socketDetails[0].digital_inputs[inputNumber] === 0 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5
                }}
              >Open
              </Text>
            }

            {inputNumber > 2 && socketDetails[0].digital_inputs[inputNumber] === 1 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5
                }}
              >Close
              </Text>
            }
          </View>
        )}
        {item.name != undefined && item.name != "" && isOnline == "0" &&
          <View
            style={{
              backgroundColor: "#009df01f",
              marginLeft: 10,
              marginBottom: 10,
              padding: 7,
              height: 100,
              width: 200
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                alignSelf: "flex-start",
                marginLeft: 5,
                marginTop: 5,
                justifyContent: "space-between",
              }}
            >
              <Tooltip popover={<Text style={{ color: 'white' }}> {item.name}</Text>}>
                <Text style={{ fontWeight: "700" }}>{item.name}</Text>
              </Tooltip>
            </View>
            <View>
              {item.index < 3 ?
                <Text style={{ alignSelf: "flex-start" }}> 0 m³</Text>
                :
                <Text style={{ alignSelf: "flex-start", marginTop: 15 }}>{""}</Text>
              }
            </View>
            {item.error != "" ? (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            ) : (
              <Text
                style={{
                  alignSelf: "flex-start",
                  marginLeft: 5,
                  color: "red",
                  marginTop: 14,
                  marginRight: 20,
                }}
              ></Text>
            )}
            {item.index < 3 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 15,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: 30 }}>--</Text> m³/h
              </Text>
            }

            {item.index > 2 &&
              <Text
                style={{
                  alignSelf: "center",
                  fontWeight: "700",
                  fontSize: 25,
                  color:
                    item.level == "Open"
                      ? "#0fbf00"
                      : item.level == "Close"
                        ? "#bababa"
                        : "#000000",
                  textAlign: "center",
                  marginVertical: 5,
                }}
              >--
              </Text>
            }
          </View>
        }
      </View>
    );
  };
  //IMEI based water meter blocks
  const renderWaterMtIMEILeft = ({ item, index }) => {
    var mainindex = index;
    var name = "";
    var totalizer = 0;
    var status = 0;

    var value = "";
    var flow = 0;
    var inputNumber = 0;
    var error = "";
    // console.log("imeiTabData", imeiTabData);
    if (imeiTabData) {
      try {
        var imeiTabDataObject = JSON.parse(imeiTabData);
        console.log("Parsed imeiTabDataObject", imeiTabDataObject);

        var tamp = imeiTabDataObject.tamp;
        // console.log("tamp", tamp);

        if (index === 0 && imeiTabDataObject) {
          var flow1 = imeiTabDataObject.flow;
          var Sol = imeiTabDataObject.sol;
          // console.log("Sol", Sol);

          if (IMEIData12.sol == 1 && imeiDataData12.flow > 0) {
            error = 'Water Leak';
          }
        }

        if (index === 1) {
          var tamp = imeiTabDataObject.tamp;
          // console.log("tamp", tamp);
        }
      } catch (e) {
        console.error("Error parsing JSON:", e);
      }
    }

    let imeiDataData;
    let totz;
    let flow12 = 0.00;

    // imei waterleak
    if (index == 0 && IMEIData12.sol == 1 && IMEIData12.flow > 0) {
      error = 'Water Leak'
    } else if (IMEIData12.sol == null) {
      error = 'Error'
    }
    try {
      console.log("IMEIData12346", IMEIData12);

      imeiDataData = JSON.parse(IMEIData12);
      totz = imeiDataData.totz;
      flow12 = IMEIData12.flow.toFixed(2);
    } catch (err) {
      // console.log("err", err);
    }

    return (
      <View style={{ justifyContent: "space-between", marginBottom: isMobile ? 10 : 0 }}>
        <View
          style={{
            backgroundColor: "#009df01f",
            marginLeft: 10,
            padding: 7,
            height: 100,
            width: 200
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "95%",
              alignSelf: "flex-start",
              marginLeft: 5,
              marginTop: 5,
              justifyContent: "space-between",
            }}
          >
            <Tooltip popover={<Text style={{ color: 'white' }}> {item.name}</Text>}>
              <Text style={{ fontWeight: "700" }}>{BasedOn == 2 ? item.name.toLowerCase() : item.name !== "" ? item.name.toLowerCase() : ""}</Text>
            </Tooltip>
          </View>
          {error ? (
            <Text
              style={{
                alignSelf: "flex-start",
                marginLeft: 0,
                color: "red",
                marginTop: 5,
                fontWeight: "700",
                marginRight: 20,
              }}
            >
              {error}
            </Text>
          ) : (
            <>
              {basedOn == 2 && item.index == 1 ?
                <Text
                  style={{
                    alignSelf: "flex-start",
                    marginLeft: 5,
                    color: "black",
                    marginRight: 20,
                    marginBottom: 15,
                  }}
                >{IMEIData12.totz} m³</Text>
                :
                <Text
                  style={{
                    alignSelf: "flex-start",
                    marginLeft: 5,
                    marginTop: 20,
                    marginBottom: 20,
                    color: "black",
                    marginRight: 20,
                  }}
                ></Text>
              }
            </>
          )}

          <Text
            style={{
              alignSelf: "center",
              fontWeight: "700",
              fontSize: 15,
              color:
                item.level == "Open"
                  ? "#0fbf00"
                  : item.level == "Close"
                    ? "#bababa"
                    : "#000000",
              textAlign: "center",
            }}
          >

            {
              basedOn == 2 ?
                item.index == 1 ?
                  <> <Text style={{ fontSize: 30 }}>{IMEIData12.flow} </Text>
                    {item.unit}</> : <Text style={{ fontSize: 20 }}>{IMEIData12.tamp === 1 ? 'Close' : 'Open'}</Text>
                :
                <>
                  {index == 1 ? (
                    <Text style={{ fontSize: 30 }}>{tamp === 1 ? 'Close' : 'Open'}</Text>
                  ) : (
                    "--"
                  )}{item.unit}

                </>
            }


          </Text>
        </View>
      </View>
    );

  };
  // Omega based Table
  const renderItemRight = (item, index) => {
    var nextIrrigationTime = "";
    var lastIrrigationTime = "";
    var lastConnection = "";
    var lastIrrigationName = "";
    var isonline = false;
    try {
      if (socketDetails[0]) {
        nextIrrigationTime = socketDetails[0].nextIrrigationTime;
        lastIrrigationTime = socketDetails[0].lastIrrigationTime;
        lastConnection = socketDetails[0].lastConnection;
        isonline = socketDetails[0].isonline;
        lastIrrigationName = socketDetails[0].lastIrrigationName;
      }
    } catch (error) {
      // console.log("error", error)
    }
    return (
      <View style={isMobile ? {
        width: 150,
        marginBottom: 10,
        padding: 10,
        borderRadius: 10,
        marginLeft: -60
      } : [styles.cardRight]}>
        <TouchableOpacity
          onPress={() => {

          }}
        >
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "#009df01f",
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, margin: 10 }}>
                <Image
                  style={{
                    height: 35,
                    width: 25,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/omega.png")}
                />
              </View>
              <View style={{ flex: 1, margin: 10 }}>

                <Text>{item.device_name}</Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Icon name="wifi" color={item.isonline == true ? "#228B22" : "#FF0000"} size={20} />
              </View>
            </View>

            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Next water supply" : "Next irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.nextIrrigationTime}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Last water supply" : "Last irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.lastIrrigationTime} ({item.lastIrrigationName})
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {isonline == true ? "Online" : item.lastConnection}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      // </View>
    );
  };

  function isWithinOnlineCheckTime(dtm, onlineCheckTime) {
    // Convert dtm to a Moment object
    const dtmMoment = moment(dtm, 'YYYYMMDDHHmmss');

    // Get the current time
    const currentTime = moment();

    // Calculate the difference in minutes
    const differenceInMinutes = currentTime.diff(dtmMoment, 'minutes');

    // Check if the difference is less than or equal to onlineCheckTime
    return differenceInMinutes < onlineCheckTime;
  }

  //IMEI based table
  const renderIMEIItemRight = (item, index) => {
    var nextIrrigationTime = "";
    var lastIrrigationTime = "";
    var lastConnection = "";
    var lastIrrigationName = "";
    let isOnline = false;
    console.log("IMEIData-----", IMEIData);

    try {
      if (IMEIData) {
        // let imeiTabDataData;
        // let dtm;
        // let stm;
        // try {
        //   imeiTabDataData = JSON.parse(imeiTabData);
        //   dtm = imeiTabDataData.dtm;
        //   stm = imeiTabDataData.stm;
        // } catch (err) {
        //   // console.log("err", err);
        // }
        // isOnline = isWithinOnlineCheckTime(dtm, onlineCheckTime);

        // if (BasedOn == 2) {
        //   nextIrrigationTime = stm;
        //   lastIrrigationTime = IMEIData.lastIrrigationTime;
        //   const parsedTimestamp = moment(dtm, 'YYYYMMDDHHmmss');
        //   const formattedTime = parsedTimestamp.format('HH:mm');
        //   lastConnection = formattedTime;
        //   lastIrrigationName = IMEIData.lastIrrigationName;
        // } else {
        //   nextIrrigationTime = IMEIData.nextIrrigationTime;
        //   lastIrrigationTime = IMEIData.lastIrrigationTime;
        //   lastConnection = IMEIData.lastConnection;
        //   lastIrrigationName = IMEIData.lastIrrigationName;
        // }
      }
    } catch (error) {
      // console.log("error", error)
    }

    return (
      <View style={isMobile ? {
        width: 150,
        marginBottom: 10,
        padding: 10,
        borderRadius: 10,
        marginLeft: -60
      } : [styles.cardRight,]}>
        <TouchableOpacity
          onPress={() => {
          }}
        >
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "#009df01f",
              },
            ]}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, margin: 10 }}>
                <Image
                  style={{
                    height: 35,
                    width: 25,
                    resizeMode: "contain",
                  }}
                  source={require("../../assets/images/omega.png")}
                />
              </View>
              <View style={{ flex: 1, margin: 10 }}>

                <Text>{BasedOn == 1 && BasedOn == null ? item.device_name : IMEIDeviceName.device_name}</Text>
              </View>
              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Icon name="wifi" color={IMEIData.isonline == true ? "#228B22" : "#FF0000"} size={20} />
              </View>
            </View>

            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Next water supply" : "Next irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {BasedOn == 2 && IMEIData.nextIrrigationTime ? IMEIData.nextIrrigationTime : '-'
                  }
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {item.project_type_id == 6 ? "Last water supply" : "Last irrigation"}
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                    textAlign: "center"
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {BasedOn == 2 && IMEIData.lastIrrigationTime ? moment(IMEIData.lastIrrigationTime).format('YYYY-MM-DD hh:mm A') : '-'
                  }
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={isMobile ? {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    paddingTop: 11,
                    justifyContent: "flex-end",
                    fontSize: 12,
                  } : {
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {BasedOn == 2 &&
                    IMEIData.isonline ? 'Online' :
                    IMEIData.lastConnection ? IMEIData.lastConnection : '-'}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      // </View>
    );
  };
  ////// Add for Extra Details Table - RohitB ////////
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const handleMouseEnter = (index) => {
    // setIsHovered(true);
    // console.log("Hovered Index:", index);
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    //setIsHovered(false);
    setHoveredIndex(-1);
  };
  const renderDetailsRight = () => {
    return BasedOn == 2 && (

      <View style={isMobile ? {
        width: 200,
        marginBottom: 10,
        padding: 10,
        borderRadius: 10,
      } : [styles.cardRight,]}>
        <TouchableOpacity
          onPress={() => {
          }}
        >
          <View
            style={[
              styles.shadowProp,
              {
                backgroundColor: "#009df01f"
              },
            ]}
          >


            {labelValues.length > 0 && <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flex: 1, margin: 10 }}>
                <Text style={{ fontWeight: "500", textAlign: "center", fontSize: 14, }}>Information</Text>
              </View>
            </View>}

            {labelValues.length > 0 ?
              <View style={{ flexDirection: "column", padding: 5 }}>
                {labelValues.map((item, index) => (
                  <View style={{ flexDirection: "row" }} key={index}>
                    {item.label && item.label.trim() !== '' && (
                      <Text
                        style={isMobile ? {
                          backgroundColor: "#D4E3EB",
                          width: "100%",
                          marginBottom: 10,
                          padding: 8,
                          justifyContent: "flex-end",
                          fontSize: 12,
                        } : {
                          backgroundColor: "#D4E3EB",
                          width: "100%",
                          marginBottom: 10,
                          padding: 8,
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {hoveredIndex === index
                          ? <Tooltip popover={<Text
                            style={{
                              //backgroundColor: "#D4E3EB",
                              width: "100%",
                              color: 'white',
                              marginTop: 5,
                              marginBottom: 5,
                              padding: 8,
                              justifyContent: "flex-end",
                              fontSize: 14,
                            }}
                          > {item.label}</Text>}>
                            <Text>{truncateText(item.label, 15)}</Text>
                          </Tooltip>
                          : truncateText(item.label, 15)}
                      </Text>
                    )}
                    {item.value && item.value.trim() !== '' && (
                      <Text
                        style={isMobile ? {
                          backgroundColor: "#D4E3EB",
                          width: "100%",
                          marginBottom: 10,
                          padding: 8,
                          justifyContent: "flex-end",
                          fontSize: 12,
                          textAlign: "center"
                        } : {
                          backgroundColor: "#D4E3EB",
                          width: "100%",
                          marginBottom: 10,
                          padding: 8,
                          justifyContent: "flex-end",
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {hoveredIndex === index
                          ? <Tooltip popover={<Text
                            style={{
                              //backgroundColor: "#D4E3EB",
                              width: "90%",
                              color: 'white',
                              marginTop: 10,
                              marginBottom: 10,
                              padding: 8,
                              justifyContent: "flex-end",
                              fontSize: 12,
                              // maxWidth: 500,
                              // maxHeight:100,
                            }}
                          > {item.value}</Text>}>
                            <Text>{truncateText(item.value, 20)}</Text>
                          </Tooltip>
                          : truncateText(item.value, 20)}
                      </Text>
                    )}
                  </View>
                ))}
              </View> :
              <View style={{ flexDirection: "column", padding: 5 }}>
                <h1>No Data Found</h1>
              </View>
            }
          </View>
        </TouchableOpacity>
      </View>
      // </View>
    );
  };

  return (
    <View style={styles.MainContainer}>
      <SuccessPopup
        visible={issuccessVisible}
        PopupMessage={PopupMessage}
        Responsestatus={responsestatus}
        onChange={() => setissuccessVisible(false)}
      />
      <Modal
        onRequestClose={() => setValveIsVisible(false)}
        transparent={true}
        visible={ValveIsVisible}
      >
        <View
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: "rgba(100,100,100, 0.5)",
          }}
        >
          <View style={[styles.containeralt]}>
            <TouchableOpacity
              style={{ alignSelf: "flex-end" }}
              onPress={() => setValveIsVisible(false)}
            >
              <IconCross
                name="circle-with-cross"
                size={20}
                color="#000"
                style={{ bottom: 5, top: 10 }}
              />
            </TouchableOpacity>

            <Text style={{ alignSelf: "center", fontWeight: "700" }}>
              Set Amount
            </Text>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RadioButton
                uncheckedColor={"#707070"}
                color={"#707070"}
                value="Duration"
                status={isMeasureType == 2 ? "checked" : "unchecked"}
                onPress={() => setMeasureType(2)}
              />
              <Text style={{ fontSize: 12, color: "#707070" }}>Duration</Text>
              <RadioButton
                color={"#707070"}
                uncheckedColor={"#707070"}
                value="Quantity"
                status={isMeasureType == 1 ? "checked" : "unchecked"}
                onPress={() => setMeasureType(1)}
              />
              <Text style={{ fontSize: 12, color: "#707070" }}>Quantity</Text>

            </View>

            <View
              style={{ width: "90%", alignSelf: "center", marginBottom: 10 }}
            >
              {isMeasureType == 2 ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timeHr < 23 && settimeHr(timeHr + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timeHr < 10 ? `0${timeHr}` : timeHr}{" "}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timeHr > 0 && settimeHr(timeHr - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginHorizontal: 5 }}>:</Text>
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timemin < 59 && settimemin(timemin + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timemin < 10 ? `0${timemin}` : timemin}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timemin > 0 && settimemin(timemin - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <Text style={{ marginHorizontal: 5 }}>:</Text>
                  <View
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <TouchableOpacity
                      onPress={() => timesec < 59 && settimesec(timesec + 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-up"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>

                    <Text
                      style={{
                        borderWidth: 1,
                        margin: 5,
                        width: 40,
                        alignContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        borderColor: "#707070",
                        borderRadius: 6,
                      }}
                    >
                      {timesec < 10 ? `0${timesec}` : timesec}
                    </Text>

                    <TouchableOpacity
                      onPress={() => timemin > 0 && settimesec(timesec - 1)}
                    >
                      <Text style={{ margin: 5 }}>
                        <IconTimer
                          color="#767676"
                          name="chevron-thin-down"
                          size={20}
                        />
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              ) : (
                <TextInput
                  style={styles.input}
                  placeholder="Enter Amount"
                  value={valveamount}
                  autoFocus={true}
                  onChangeText={(text) => setvalveamount(text)}
                />
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-evenly",
                marginHorizontal: 20,
              }}
            >
              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  getCounts(1)
                  setValveIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Save</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.saveBtn}
                onPress={() => {
                  setValveIsVisible(false);
                }}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      <View style={{ flexDirection: "row", width: "100%", marginTop: "-1%" }}>
        <View style={{ flex: 1, marginHorizontal: 10 }}>
          <Text style={styles.lableText}>
            Monitor {">"}
            <Text onPress={() => navigation.navigate("GridView")}>
              Grid View
            </Text>
            {">"} {project_name} {">"} {OmegaName}
          </Text>
        </View>
      </View>
      {isLoadingSync ? (
        <ActivityIndicator
          color="#1DA1F2"
          visible={isLoadingSync}
          textContent={"Loading..."}
          size={100}
        />
      ) : (
        <View>
          <View
            style={{
              flexDirection: "row",
              marginTop: "0%",
              flexWrap: "wrap-reverse",
            }}
          >
            <MenuProvider
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {/* {valveDetails != null ?
                (
                  valveDetails.length > 0 && (
                    valveDetails.map((item, index) => {
                      return renderValveItem(item, index);
                    })
                  )
                )
                :
                (
                  // valveDetails.length == 0 && (
                  <View style={{ justifyContent: "center" }}>
                    <Text
                      style={[
                        styles.emptyListStyle,
                        { alignSelf: "center", marginTop: 20 },
                      ]}
                    >
                      No Valve Data Found{" "}
                    </Text>
                  </View>
                  // )
                )
              } */}
              {BasedOn == 2 ?
                (ValveIMEIData && ValveIMEIData.length > 0 &&
                  ValveIMEIData.map((item, index) => renderValveIMEIItem(item, index))
                ) :
                (valveDetails != null ?
                  (valveDetails.length > 0 &&
                    valveDetails.map((item, index) => renderValveItem(item, index))
                  ) :
                  (
                    <View style={{ justifyContent: "center" }}>
                      <Text
                        style={[
                          styles.emptyListStyle,
                          { alignSelf: "center", marginTop: 20 },
                        ]}
                      >
                        No Valve Data Found{" "}
                      </Text>
                    </View>
                  )
                )
              }
            </MenuProvider>

            <View
              style={{
                flexDirection: "row",
                width: "25%",
              }}
            >
              {BasedOn == 2 ?
                renderIMEIItemRight(IMEIData, 0) :
                OmegaDetailsGrid.map((item, index) => renderItemRight(item, index))
              }
              {/* {OmegaDetailsGrid.map((item, index) => {
                return renderItemRight(item, index);
              })} */}
            </View>
          </View>
          <View
            style={{
              marginTop: "2%",
              width: "100%",
              //flexDirection: "row",
              flexDirection: isMobile ? "column" : "row", // add for Extra details - RohitB
              // justifyContent: "space-between",
              // alignItems: "flex-start",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {BasedOn == 2 ?
                <FlatList
                  numColumns={isMobile ? 1 : 2}
                  //style={{ width: "75%" }}
                  style={{ width: isMobile ? "100%" : "75%" }}// add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={pressureIMEIData}
                  renderItem={renderPressureIMEILeft}
                />
                :
                <FlatList
                  numColumns={1}
                  //style={{ width: "75%" }}
                  style={{ width: isMobile ? "100%" : "75%" }}// add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}
                  // horizontal
                  data={analogDetails}
                  renderItem={renderItemLeft}
                />
              }
            </View>
            <View
              style={{
                //flexDirection: "row",
                flexWrap: 'wrap',
                flexDirection: isMobile ? "column" : "row",// add For Extradetails table- RohitB
                marginRight: '14%',
                //  //marginRight: isMobile ? 0 : "14%"
              }}
            >
              {BasedOn == 2 ?
                <FlatList
                  numColumns={isMobile ? 1 : 2}
                  //style={{ width: "75%", }}
                  style={{ width: isMobile ? "100%" : "75%" }}// add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}

                  // horizontal
                  data={waterIMEIData}
                  renderItem={renderWaterMtIMEILeft}
                />
                :
                <FlatList
                  //numColumns={3}
                  numColumns={isMobile ? 1 : 3}// add For Extradetails table- RohitB
                  //style={{ width: "75%", }}
                  style={{ width: isMobile ? "100%" : "75%" }}// add For Extradetails table- RohitB
                  contentContainerStyle={{
                    padding: 5,
                  }}

                  // horizontal
                  data={waterMeterDetails}
                  renderItem={renderWaterMtLeft}
                />
              }
            </View>
            <View style={{             // add For Extradetails table- RohitB
              flexDirection: "row",
              width: "25%",
              //marginLeft:"-20px",
              // justifyContent:'flex-end',
              position: 'absolute',
              // bottom:0,
              right: 0,
              marginLeft: 10,

            }}>
              {renderDetailsRight()}
            </View>
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    padding: 20,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
  },
  btnTextCancel: {
    color: "#747474",
    fontWeight: "600",
    textAlign: "center",
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  input: {
    borderColor: "gray",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    // marginLeft: 20,
    padding: 10,
  },
  btnText: {
    color: "#fff",
    fontWeight: "600",
  },
  saveBtn: {
    alignItems: "center",
    backgroundColor: "#006EB9",
    padding: 10,
    borderWidth: 1,
    borderColor: "#006EB9",
    width: "50%",
    marginRight: 10,
  },
  containeralt: {
    // alignItems: "center",
    backgroundColor: "#F8F8F8",
    borderColor: "#eee",
    borderRadius: 10,
    borderWidth: 1,
    // justifyContent: "center",
    margin: "auto",
    padding: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "30%",
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
  },

  // cardLeft: {
  //   width: "15%",
  //   // flex: 0.5,

  //   marginVertical: 10,
  //   padding: 10,
  //   // flexDirection: 'row'
  //   borderRadius: 10,
  // },
  cardRight: {
    width: "100%",
    // flex: 0.5,
    marginBottom: 10,
    padding: 10,
    // flexDirection: 'row'
    borderRadius: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
});
